import React from 'react';
import {Link} from "react-router-dom";
export const projectsRaw = [
	{
		link: 'niar',
		title: 'niar',
		time: 'Ongoing since Apr 2020',
		caption: <div>
			<p>A C++/Vulkan toy renderer where I implement graphics features for learning purposes (see the repository for details).</p>
		</div>,
		onHomePage: true
	},
	{
		link: 'blm-in-the-shell',
		title: 'Black Mage in the Shell',
		time: 'Ongoing since Feb 2022',
		caption: <div>
			A web-based tool that simulates and visualizes Final Fantasy XIV Black Mage gameplay. It helps players create and optimize fight strategies.
		</div>,
		onHomePage: false
	},
	{
		link: 'echoes',
		title: 'Building Echoes\' 2.5D Art Pipeline',
		time: 'Aug 2023 - May 2024 (9 months)',
		caption: <div>
			Documentation of my work as a technical artist on game Echoes.
		</div>,
		onHomePage: true,
		toc: <div>
			<h2>Table of Contents</h2>
			<Link to="/page/echoes">(Back to top)</Link>
			<h3><Link to="/page/echoes/basic-rendering-pipeline">Basic rendering pipeline</Link></h3>
			<ul>
				<li><Link to="/page/echoes/why-2.5d">Why 2.5D</Link></li>
				<li><Link to="/page/echoes/how-is-each-object-rendered">How is each object rendered</Link></li>
				<li><Link to="/page/echoes/how-lighting-works">How lighting works</Link></li>
				<li><Link to="/page/echoes/the-rendering-loop">The rendering loop</Link></li>
			</ul>
			<h3>
				<Link to="/page/echoes/additional-graphics-features">Additional graphics features</Link>
			</h3>
			<ul>
				<li><Link to="/page/echoes/fog-of-war">Fog of war</Link></li>
				<li><Link to="/page/echoes/water">Water</Link></li>
				<li><Link to="/page/echoes/animated-grass">Animated grass</Link></li>
				<li><Link to="/page/echoes/shadow">Shadow</Link></li>
				<li><Link to="/page/echoes/others">Others</Link></li>
			</ul>
			<h3><Link to="/page/echoes/tools-and-workflow">Tools and workflow</Link></h3>
			<ul>
				<li><Link to="/page/echoes/level-editor">Level editor</Link></li>
				<li><Link to="/page/echoes/spriteconverter-custom-inspector">Sprite converter</Link></li>
				<li><Link to="/page/echoes/echoes-exporter">Echoes exporter</Link></li>
				<li><Link to="/page/echoes/island-blueprints">Island blueprints</Link></li>
			</ul>
			<h3><Link to="/page/echoes/final-thoughts">Final thoughts</Link></h3>
		</div>
	},
	{
		link: 'lumiland',
		title: 'Lumiland',
		time: 'May 2020 - Feb 2021 (9 months)',
		caption: <div>A 3D block pushing puzzle game. It was released on Steam in February 2021.
			I was responsible for everything except sound and designing the advanced levels.
		</div>,
		onHomePage: true
	},
	{
		link: 'harvest-island',
		title: 'Harvest Island',
		time: 'Oct 2019 - Dec 2019 for a class (6 weeks)',
		caption: <div>
			A farming puzzle game made by a team of 4 programmed in C++/OpenGL. I made most of the art assets and implemented many art-related features.
		</div>,
		onHomePage: true
	},
	{
		link: 'small-rhythm-game',
		title: 'Small rhythm game',
		time: 'Fall 2019 for a class (1 week)',
		caption: <div>
			An untitled rhythm game with a custom level editing tool. Programmed in C++/OpenGL. I was responsible for all aspects except creating the soundtrack.
		</div>,
		onHomePage: true
	},
	{
		link: 'small-rpg',
		title: 'Small RPG',
		time: 'Fall 2019 for a class (1 week)',
		caption: <div>
			An untitled small RPG with a simple animation (sprite flickering) editing script format and a custom font loader. Programmed in C++/OpenGL. I was responsible for all aspects.
		</div>,
		onHomePage: true
	},
	{
		link: 'paper-glider',
		title: 'Paper Glider',
		time: 'Fall 2019 for a class (1 week)',
		caption: <div>Control the paper-glider and navigate through a mystic city. Programmed in C++/OpenGL. I was responsible for all aspects.</div>,
		onHomePage: true
	},
	{
		link: 'old-projects',
		title: 'Archived old projects',
		time: 'Most were before 2019',
		caption: <div>Early projects that no longer represent my technical abilities.</div>,
		onHomePage: true
	},
	{
		link: 'grass-sim',
		title: '[collapse] Real-time Procedural Grass with GLSL Shaders',
		caption: `
			A real-time procedural grass system implemented with a GLSL compute shader.`,
		onHomePage: false
	},
	{
		link: 'skating-mocap',
		title: 'Skater\'s Motion Capture Data Cleanup & Visualization',
		caption: `
			I cleaned up some figure skating data extracted from video using OpenPose 
			and redrew the skater as a stick figure.`,
		onHomePage: false
	},
	{
		link: 'conformity',
		title: '[archive] Conformity',
		caption: `
			A 2D game written in Processing. It shows the relationship between the crowd and the 
			self-motivated individuals who act against it.`,
		onHomePage: false
	},
	{
		link: 'asemic-postcard',
		title: '[archive] Asemic Postcard',
		caption: `
			A machine-written postcard. After the characters were designed in 
			a Processing program, the paragraphs were generated according 
			to a set of layout rules.`,
		onHomePage: false
	},
	{
		link: 'tetratetris',
		title: 'Tetratetris',
		caption: `
			A modified version of Tetris. Instead of rotating the falling piece, 
			the player rotates the entire game board. Programming was done in C++.`,
		onHomePage: false
	},
	{
		link: 'malleable-waves',
		title: '[archive] Malleable Waves',
		caption: `
			An interactive art project. The projection part is written in Processing.`,
		onHomePage: false
	},
	{
		link: 'mirror',
		title: 'Mirror',
		caption: `
			An interactive art project built with P5.js that simulates an (unsuccessful) process of self-exploration.`,
		onHomePage: false
	},
	{
		link: 'electric-ghost',
		title: 'Electric Ghost',
		caption: `
			iOS app, final project for 60-212 with professor Golan Levin. 
			It visualizes a ghost-like, imaginary creature 
			that lives in electric outlets and reacts when touched by users.`,
		onHomePage: false
	},
	{
		link: 'bot-a-razzi',
		title: 'bot-a-razzi',
		caption: `
			A collaborative art project that uses VR environments and projection to represent a 
			futuristic world.`,
		onHomePage: false
	},
	{
		link: 'tic-tac-toe',
		title: 'Tic Tac Toe',
		caption: `
			This is an implementation of game Tic Tac Toe, as well as an unbeatable AI of it.`,
		onHomePage: false
	},
	{
		link: 'pattern-generator',
		title: 'Pattern Generator',
		caption: `
			Written with P5.js, this pattern is ready to be embedded in technology-themes
			graphic design projects.`,
		onHomePage: false
	}
];